import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation, Trans } from 'react-i18next';


export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const { t } = useTranslation();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const faqItems = [
    { question: 'FAQ.question1', answer: 'FAQ.answer1' },
    { question: 'FAQ.question2', answer: 'FAQ.answer2' },
    { question: 'FAQ.question3', answer: 'FAQ.answer3' },
    { question: 'FAQ.question4', answer: 'FAQ.answer4' },
    { question: 'FAQ.question5', answer: 'FAQ.answer5' },
    { question: 'FAQ.question6', answer: 'FAQ.answer6' },
    { question: 'FAQ.question7', answer: 'FAQ.answer7' },
    { question: 'FAQ.question8', answer: 'FAQ.answer8' },
    { question: 'FAQ.question9', answer: 'FAQ.answer9' },
    { question: 'FAQ.question10', answer: 'FAQ.answer10' },
    { question: 'FAQ.question11', answer: 'FAQ.answer11' }
  ];

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqItems.map((item: any, index: any) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {t(item.question)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                {t(item.answer)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

      </Box>
    </Container>
  );
}
